<template>
  <div class="wrapper">
    <img loading="lazy" alt="icon" id="img-top" src="/images/emprendedores/incubadora.jpg" />
    <div class="background first">
      <div class="container">
        <h2>{{ $t('emprendedores.incubator_of_entrepreneurs') }}</h2>
        <p>
          Si eres emprendedor junior y quieres poner en marcha tu idea empresaria
           o ya lo has iniciado el club Emprendedores te ofrece espacio de trabajo
           y apoyo técnico para impulsar tu proyecto. 
        </p>
        <p>
          La Incubadora del Club de Emprendedores se crea en el corazón de las
          actividades que realiza la unidad para prestar diferentes servicios a los
          emprendedores, con ideas de negocio innovadoras y que busquen un impulso
          para lanzar sus proyectos al mercado.
        </p>
        <div>
          <div class="bullet">
            <img loading="lazy" alt="icon" src="/images/emprendedores/bullet.png" />
            <span>
              Ofrece a los emprendedores ubicados en ella espacios de trabajo para
              que desarrollen las primeras etapas de sus empresas.
            </span>
          </div>
          <div class="bullet">
            <img loading="lazy" alt="icon" src="/images/emprendedores/bullet.png" />
            <span>
              Aporta apoyo técnico con asesoramiento empresarial para acelerar sus
              iniciativas.
            </span>
          </div>
        </div>
      </div>
    <Contacts />
    <b-row class="clearfix" style="height: 50px;"></b-row>
    </div>
  </div>
</template>

<script>
import Contacts from '../../components/ContactsForm'
export default {
  components: {
    Contacts
  },
  metaInfo() {
    return {
      title: this.$t('emprendedores.incubator_of_entrepreneurs'),
      meta: [
        // OpenGraph
        { vmid: "og:title", property: "og:title",  content: this.$t('emprendedores.incubator_of_entrepreneurs') + ' - Club de Ejecutivos' },
        { vmid: "og:type", property: 'og:type', content: 'website' },
        // Google / Schema.org markup:
        { itemprop: 'name', content: this.$t('emprendedores.incubator_of_entrepreneurs') + ' - Club de Ejecutivos' },
      ]
    }
  },
}
</script>

<style scoped lang="scss">
#img-top {
  width: 100%;
}

.background.first {
  background: #f2f2f2;

  .container {
    text-align: left;
    padding: 50px 15px;

    .bullet {
      margin-bottom: 15px;
    }
  }
}
</style>